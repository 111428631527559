import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'; 

function ContactBox () {
    return (  
        <div className='s-box'>
           
            <div className='s-b-text'>
            <h1 className='box-header'><span>Please get in touch</span></h1>
            
            </div>
            <div className='contactIcons'>
                <FontAwesomeIcon icon={faWhatsapp} className='whatsApp'></FontAwesomeIcon>
                <span class="calendar-day">07579762585</span>
                <FontAwesomeIcon icon={faPhone} className='phone'></FontAwesomeIcon>
                <span class="calendar-day"></span>
                <FontAwesomeIcon icon={faEnvelope} className='envelope'></FontAwesomeIcon>
                <span class="calendar-day">info@jcrhealthcare.co.uk</span>
                
            </div>
            
        </div>
    );
}
 
export default ContactBox;