import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'; 

function Footer () {
    return (
        <div className='footer'>
         <div className='contactIconsOnFooter'>
                <FontAwesomeIcon icon={faWhatsapp} className='whatsAppF'></FontAwesomeIcon>
                <span class="calendar-day">07579762585</span>
                <span class="calendar-day"></span>
                <FontAwesomeIcon icon={faEnvelope} className='envelopeF'></FontAwesomeIcon>
                <span class="calendar-day">info@jcrhealthcare.co.uk</span>
            </div>
      </div>
    );
    };
  
  export default Footer;