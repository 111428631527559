import React from 'react';
function Header () {
    return (  
        <div id='main'>
            <div className='name'>
                <h1><span>JCR Healthcare</span></h1>
                <h1>Recruit</h1>
                <p className='details'>We recruit for nursing homes, care homes, residential, mental health and learning disability</p>
            </div>
        </div>
    );
}
 
export default Header;