import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 

function ContactMap () {
    return ( 
        <div className='s-box'>
            <div className='s-b-text'>
            <h1 className='box-header'><span>Please get in touch</span></h1>
            </div>
            <div className='contactIcons'>
                <span class="address-line">51 Bournemore Avenue</span>
                <span class="address-line">NG11 9LU</span>
                <span class="address-line">Nottingham</span>
            </div>
        </div>
    );
}
 
export default ContactMap;