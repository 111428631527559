import React from 'react';
import Box from './Box';
import Box1 from './Box1';
import image1 from '../images/care1.jpg';
import image2 from '../images/care2.jpg';


function Services () {
    return (  
      <div id='services'>
            <div className='s-heading'>
            <div className='s-heading'>
        <h1>Our services</h1>
        
      </div>
      </div>
        <div className='b-container'>
        <Box image={image1} alt='image1'/>
        <Box1 image={image2} alt='image2'/>
        </div>
      </div>
    );
}
 
export default Services;